import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { CustomerForm } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "customerform"
    }}>{`CustomerForm`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import CustomerForm from '@mfrm/mfcl/CustomerForm'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Use this common form to create a customer.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={CustomerForm} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Component\n  initialState={{\n    customerForm: {\n      firstName: \'\',\n      lastName: \'\',\n      email: \'\',\n      mobilePhone: \'\',\n      additionalContact: \'\',\n    },\n    serverError: \'\',\n    validationErrors: {},\n  }}\n>\n  {({ setState, state }) => {\n    const handleInputChange = ({ target }) => {\n      const newFiledValue = {\n        [target.name]: target.value,\n      }\n      const newCustomerForm = {\n        ...state,\n        customerForm: {\n          ...state.customerForm,\n          ...newFiledValue,\n        },\n      }\n      setState(newCustomerForm)\n    }\n    const handleReset = () => {\n      setState({\n        customerForm: {\n          firstName: \'\',\n          lastName: \'\',\n          email: \'\',\n          mobilePhone: \'\',\n          additionalContact: \'\',\n        },\n        serverError: \'\',\n        validationErrors: {},\n      })\n    }\n    return (\n      <CustomerForm\n        customerForm={state.customerForm}\n        serverError={\'\'}\n        validationErrors={{}}\n        onInputChange={handleInputChange}\n        onReset={handleReset}\n        onServerErrorClose={() => {}}\n        onSubmit={() => {}}\n        startNewMMButtonText=\"Start MattressMatcher®\"\n        onStartMMClick={() => {}}\n      />\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      CustomerForm,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        customerForm: {
          firstName: '',
          lastName: '',
          email: '',
          mobilePhone: '',
          additionalContact: ''
        },
        serverError: '',
        validationErrors: {}
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          const handleInputChange = ({
            target
          }) => {
            const newFiledValue = {
              [target.name]: target.value
            };
            const newCustomerForm = {
              ...state,
              customerForm: {
                ...state.customerForm,
                ...newFiledValue
              }
            };
            setState(newCustomerForm);
          };
          const handleReset = () => {
            setState({
              customerForm: {
                firstName: '',
                lastName: '',
                email: '',
                mobilePhone: '',
                additionalContact: ''
              },
              serverError: '',
              validationErrors: {}
            });
          };
          return <CustomerForm customerForm={state.customerForm} serverError={''} validationErrors={{}} onInputChange={handleInputChange} onReset={handleReset} onServerErrorClose={() => {}} onSubmit={() => {}} startNewMMButtonText="Start MattressMatcher®" onStartMMClick={() => {}} mdxType="CustomerForm" />;
        }}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      